import { PaymentIntent } from "@stripe/stripe-js";
import { Encrypted } from "common/models/common/types";
import { Stripe } from "stripe";
import { openApiUuid } from "../../common/types";
import {
  openApiPlateCountry,
  openApiPlateText,
  openApiRegionCode,
} from "../plates/types";

export type PaymentOption = {
  labelEncrypted?: Encrypted;
  label?: string;
  filterKey: "company" | "contractProvider" | "payment";
  value: Encrypted | string;
};

export type PaymentOptionsResponse = PaymentOption[];

export enum PaymentOptions {
  Provider = "provider",
  EasyPark = "easyPark",
  Parkkimaksu = "parkkimaksu",
  ParkkimaksuTerminal = "parkkimaksuTerminal",
  Contract = "contract",
  Invoice = "invoice",
  PlateContract = "plateContract",
  NoPayment = "noPayment",
}

export enum PaymentStatuses {
  Open = "open",
  InProgress = "in progress",
  Failed = "failed",
  Complete = "complete",
}

export const chargePaymentOptions = [
  PaymentOptions.Invoice,
  PaymentOptions.Parkkimaksu,
  PaymentOptions.ParkkimaksuTerminal,
  PaymentOptions.PlateContract,
  PaymentOptions.Contract,
  PaymentOptions.EasyPark,
  PaymentOptions.Provider,
] as const;

export type PaymentMethod =
  | (typeof chargePaymentOptions)[number]
  | PaymentOptions.EasyPark
  | PaymentOptions.Provider;

export const isChargePaymentOption = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: any
): option is (typeof chargePaymentOptions)[number] =>
  chargePaymentOptions.includes(option);

export enum ReceivableStatus {
  PENDING = "PENDING",
  CARHOLDER_RESOLUTION_FAILED = "CARHOLDER_RESOLUTION_FAILED",
  INVOICE_CREATED = "INVOICE_CREATED",
}

export type PaymentsDb = {
  id: number;
  billingEndTime?: Date | null;
  price?: number | null;
  exemptedBy?: string | null;
  shouldCheckExit?: boolean;
  inDetectionDate?: Date | null;
  inLaneId?: string;
  inVehicleId?: string;
  outDetectionDate?: Date | null;
  outLaneId?: string;
  outVehicleId?: string;
  orphanedDate?: Date | null;
  makesOrphanVehicleId?: string;
  manualStartDate?: Date | null;
  manualStopDate?: Date | null;
  hallId: string;
  plateText?: string | null;
  plateCountry?: string | null;
  regionCode?: string | null;
  entranceDetectionId?: number;
  externalId?: string | null;
};

export type PaymentsDbInput = Omit<
  PaymentsDb,
  | "id"
  | "billingEndTime"
  | "modifiedAt"
  | "manualStartDate"
  | "manualStopDate"
  | "inDetectionDate"
  | "outDetectionDate"
  | "orphanedDate"
> & {
  billingEndTime?: Date | string | null;
  manualStartDate?: Date | string | null;
  manualStopDate?: Date | string | null;
  inDetectionDate?: Date | string | null;
  outDetectionDate?: Date | string | null;
  orphanedDate?: Date | string | null;
};

export type ChargesDb = {
  /**
   * Connects this charge to payments table.
   */
  visitId: number;
  paymentIntentId?: string | null;
  paymentIntentStatus?: string | null;
  cardNumber?: string | null;

  /**
   * Refers to a specific act of payment (not rows in payments table).
   *
   * Multiple charges can have the same payment ID if multiple visits are paid at once.
   */
  paymentId?: string;
  receivableId?: string | null;
  receivableStatus?: ReceivableStatus | null;
  price: number | null;
  modifiedAt: Date;
  paymentMethod: PaymentMethod;
  providerId?: string | null;
  paymentName?: string | null;
  paymentNameEncrypted?: Encrypted | null;
  plateContractId?: number | null;
  companyContractId?: number | null;
  startDate?: Date | null;
  endDate?: Date | null;
};

export type ChargesDbInput = Omit<
  ChargesDb,
  "startDate" | "endDate" | "modifiedAt"
> & {
  startDate?: Date | string | null;
  endDate?: Date | string | null;
  modifiedAt?: Date | string;
};

export type ChargePaymentData = (
  | {
      paymentIntentId: string;
      paymentIntentStatus: typeof PAYMENT_FINISHED_STATUS;
      paymentMethod: PaymentOptions.Parkkimaksu;
    }
  | {
      paymentMethod: PaymentOptions.ParkkimaksuTerminal;
    }
) &
  Partial<
    Omit<
      ChargesDb,
      | "entranceDetectionId"
      | "modifiedAt"
      | "paymentIntentId"
      | "paymentIntentStatus"
      | "paymentMethod"
    >
  >;

export const PAYMENT_FINISHED_STATUS: PaymentIntent["status"] = "succeeded";
export const PAYMENT_CANCELED_STATUS: PaymentIntent["status"] = "canceled";
export const PAYMENT_METHOD_REQUIRED_STATUS: PaymentIntent["status"] =
  "requires_payment_method";
export const NO_CANCEL_PAYMENT_STATUSES: PaymentIntent["status"][] = [
  PAYMENT_FINISHED_STATUS,
  PAYMENT_CANCELED_STATUS,
];

export type PaymentVisitsPayload = {
  visits: number[];
};

export interface StripeCreateIntentResponse {
  paymentId: string;
  clientSecret: string;
  status: Stripe.PaymentIntent["status"];
  paymentIntentPrice: number;
}

export interface StripeConfigResponse {
  publishableKey: string;
}

export type NetsPaymentInput = PaymentVisitsPayload & {
  terminalId: string;
};

export type NetsPaymentResponse = Record<string, never>;

export type NetsPaymentStatus = {
  terminalId: string;
  isInProgress: boolean;
  error?: string | null;
};

export const openApiPaymentPayload = {
  type: "object",
  required: ["visits"],
  properties: {
    visits: {
      type: "array",
      items: { type: "number" },
    },
  },
};

export const openApiExemptPaymentPayload = {
  type: "boolean",
};

export const openApiFinishParkingPayload = {
  type: "string",
  format: "date-time",
};

export const openApiNetsPaymentInput = {
  type: "object",
  required: [...openApiPaymentPayload.required, "terminalId"],
  properties: {
    ...openApiPaymentPayload.properties,
    terminalId: {
      type: "string",
      minLength: 1,
    },
  },
};

export const openApiNetsPaymentResponse = {
  type: "object",
  required: ["paymentId"],
  properties: {
    paymentId: {
      type: "string",
      format: "uuid",
    },
  },
};

export const openApiNetsPaymentStatus = {
  type: "object",
  required: ["terminalId", "isInProgreess"],
  properties: {
    terminalId: {
      type: "string",
      minLength: 1,
    },
    isInProgress: {
      type: "boolean",
    },
    error: {
      type: "string",
    },
  },
};

export type ReceiptRequest = {
  paymentId: string;
  email: string;
};

export const openApiReceiptRequest = {
  type: "object",
  required: ["paymentId", "email"],
  properties: {
    paymentId: {
      type: "string",
      format: "uuid",
    },
    email: {
      type: "string",
      format: "email",
      description: "Email to send receipt to",
    },
  },
};

export type ReceiptsRequest = {
  "paymentId[]": [string, ...string[]];
};

export type PaymentReceiptText = {
  paymentId: string;
  receiptText: string;
};

export const openApiReceiptsRequest = {
  type: "object",
  required: ["paymentId[]"],
  properties: {
    "paymentId[]": {
      type: "array",
      items: openApiUuid,
      minItems: 1,
    },
  },
};

export type ReceiptsLinkRequest = {
  plateText: string;
  plateCountry?: string;
  regionCode?: string;
  cardNumber: string;
  limit?: string;
};

export type PlateCardPaymentsFilter = Omit<ReceiptsLinkRequest, "limit"> & {
  limit?: number;
};

export const openApiReceiptsLinkRequest = {
  type: "object",
  required: ["plateText", "cardNumber"],
  properties: {
    plateText: openApiPlateText,
    plateCountry: openApiPlateCountry,
    regionCode: openApiRegionCode,
    cardNumber: {
      type: "string",
      minLength: 4,
      maxLength: 4,
    },
    limit: {
      type: "string",
    },
  },
};

export enum ReceiptsLinkErrors {
  CountryAmbiguous = "PLATE_COUNTRY_AMBIGUOUS",
  RegionAmbiguous = "REGION_CODE_AMBIGUOUS",
  CountryAndRegionAmbiguous = "PLATE_COUNTRY_AND_REGION_CODE_AMBIGUOUS",
}

export const openApiReceiptsLinkError = {
  type: "object",
  required: ["error"],
  properties: {
    error: {
      type: "string",
      enum: Object.values(ReceiptsLinkErrors),
    },
  },
};
